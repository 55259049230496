import L from 'leaflet';

function initializeMaps() {
    const sections = document.querySelectorAll('.map');

    if (sections.length > 0) {

        const createPopupHtml = (address, image) => {
            let imgHtml = image ? `<img src="${image.url}" alt="${image.alt}" title="${image.title}" style="width:auto; max-height:48px; margin-bottom:5px;">` : '';
            return `
            <div class="map__popup">
                ${imgHtml}
                <div class="map__popup-address">
                    ${address}
                </div>
            </div>`;
        };

        var mapElementAttr = document.querySelector('.map');

        var markerIconUrl = mapElementAttr.getAttribute('data-marker-icon');
        var markerShadowUrl = mapElementAttr.getAttribute('data-marker-shadow');

        var myIcon = L.icon({
            iconUrl: markerIconUrl,
            shadowUrl: markerShadowUrl,
        });

        const addMarker = (map, lat, lng, address, image) => {
            if (!lat || !lng || !address) return null;

            const marker = L.marker([lat, lng], {
                icon: myIcon,
                opacity: 1, // Ensure marker is fully visible'
            }).addTo(map);

            const popupContent = createPopupHtml(address, image);
            marker.bindPopup(popupContent, {
                autoClose: false,
                closeOnClick: false,
            });

            return marker;
        };

        sections.forEach(section => {
            const mapElement = section.querySelector('.map__container');
            const settings = JSON.parse(mapElement.getAttribute('data-settings'));
            const bounds = L.latLngBounds();

            const map = L.map(mapElement, {
                zoomControl: false,
            });

            var attribution = '&copy; 2024 &middot; <a href="https://maps.omniscale.com/">Omniscale</a> ' +
            '&middot; Map data: <a href="https://www.openstreetmap.org/copyright">OpenStreetMap  (Lizenz: ODbL)</a>';

            L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
                maxZoom: 19,
                attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
                // style: L.MaptilerStyle.STREETS
            }).addTo(map);

            let markers = [];

            if(settings){
                settings.forEach((setting, index) => {
                    const marker = addMarker(map, setting.lat, setting.lng, setting.address, setting.image);
                    if (marker) {
                        markers.push(marker);
                        bounds.extend([setting.lat, setting.lng]);
                    }
    
                    if (index === 0) {
                        map.setView([setting.lat, setting.lng], 5);
                    }
                });
            }
           
            if (markers.length > 0) {
                const currentCenter = map.getCenter();
                map.setView([currentCenter.lat + 15, currentCenter.lng], map.getZoom());
                // map.fitBounds(bounds, { padding: [50, 50], maxZoom: 5 });
            } else {
                map.setView([52.5, 21.7], 5);
            }

            map.whenReady(() => {
                markers.forEach(marker => marker.openPopup());
            });

            map.scrollWheelZoom.disable();
            map.dragging.disable();

            mapElement.addEventListener('click', function() {
                map.scrollWheelZoom.enable();
                map.dragging.enable();
            });
        });
    }
}
initializeMaps();

// $(document).on('dynamicContentLoaded', function() {
//     initializeMaps();
// });
